import { useWeb3React } from '@telekomconsalting/core'
import BigNumber from 'bignumber.js'
import classNames from 'classnames'
import React, { useEffect, useReducer, useRef } from 'react'
import { Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { cancelTxn, errorTxn, resetTxn, startTxn, updateTxn } from '../../actions'
import * as actions from '../../actions'
import { setModalVerifyOpen } from '../../actions/uiActions'
import * as ConfigSettings from '../../config/settings'
import { RPC_ERR } from '../../config/settings'
import {
  BALANCE_POOLING_TIMEOUT,
  REQUEST_DENIED_BY_USER_ERROR_CODE,
  RPC_ERROR_MESSAGE,
  RPC_ERROR_MESSAGE_POLYGON,
} from '../../constants'
import { useAuth } from '../../containers/AuthWrapper'
import TradeTypeEnum from '../../enums/TradeTypeEnum'
import noop from '../../helpers/noop'
import { StatefulTransaction, TokenSpenders, TradeformType, TradeType } from '../../model'
import { State } from '../../reducers'
import { TxnUpdate } from '../../reducers/txn'
import { getUserBalance } from '../../services/accountService'
import * as transactionService from '../../services/transactionService'
import { getNetworkConfigByChainId } from '../../utils'
import FormButtonDesktop from '../buttons/FormButtonDesktop'
import { Heading } from '../Heading'
import LimitOrderConfirmationModal from '../LimitOrders/LimitOrderConfirmationModal'
import Message from '../Message'
import FormFrom from './components/FormFrom'
import FormLimit from './components/FormLimit'
import {
  ActionTypes,
  formReducer,
  FormState,
  FormStateTypes,
  initState,
} from './formDesktopResources'
import FormTo from './FormTo'
import { FormVariants, getSellAmount } from './helpers'
import { useDgWrappedTokens, useMounted, useTokens, useUpdate } from './hooks'

const ModalVerify = React.lazy(() => import('../ModalVerify'))

interface FormProps {
  tradeType: TradeType
  formVariant: TradeformType
  onSelectTradeType: (value: string) => void
}

export function FormDesktop(props: FormProps): React.ReactElement {
  const reduxDispatch = useDispatch()
  const [state, dispatch] = useReducer(formReducer, initState)
  const balancePollingInterval = useRef<ReturnType<typeof setInterval> | undefined>(undefined)
  const mounted = useMounted()
  const { library, chainId, account } = useWeb3React()
  const storedSettings = useSelector((store: State) => store.settings.settingsData)
  const slippage = storedSettings.slippage || ConfigSettings.DEFAULT_SLIPPAGE
  const walletNetwork = getNetworkConfigByChainId(chainId)?.name

  const txn: StatefulTransaction = useSelector((state: State) => state.txn[props.tradeType])
  const contrType = props.tradeType === 'Buy' ? 'Sell' : 'Buy'
  const contrTxn = useSelector((state: State) => state.txn[contrType])

  const activeTradeType = useSelector((state: State) => state.activeTradeType)

  const { tokenTo, tokenFrom } = useDgWrappedTokens({
    ...useTokens(props.tradeType),
    formVariant: props.formVariant,
  })
  const spender =
    props.formVariant === FormVariants.limit ? TokenSpenders.oneInch : TokenSpenders.zeroX
  const approvalContext =
    props.formVariant === FormVariants.limit ? tokenFrom?.oneInch : tokenFrom?.zeroX
  const update = useUpdate(props.tradeType)
  const { openProviderMenu } = useAuth()
  const clearBalancePollingInterval = (): void => {
    balancePollingInterval.current && clearInterval(balancePollingInterval.current)
    balancePollingInterval.current = undefined
  }

  const handleVisibilityChange = async (): Promise<void> => {
    clearBalancePollingInterval()
    if (document.visibilityState === 'visible') {
      if (!balancePollingInterval.current) {
        balancePollingInterval.current = setInterval(
          handleVisibilityChange,
          BALANCE_POOLING_TIMEOUT
        )
      }
      const serviceProps = getServiceProps()
      const balance = await getUserBalance(serviceProps)

      dispatch({ type: ActionTypes.ChangeBalance, balance })
    }
  }

  const { modalVerifyOpen } = useSelector((state: State) => state.ui)

  const getServiceProps = (): transactionService.ServiceParams => {
    const latestReceiptBlockNumber =
      txn.receipt?.blockNumber &&
      contrTxn.receipt?.blockNumber &&
      Math.max(txn.receipt?.blockNumber, contrTxn.receipt?.blockNumber)
    return {
      txn,
      blockNumber: latestReceiptBlockNumber,
      account,
      library,
      chainId,
      walletNetwork,
      slippage,
      tokenFrom,
      tokenTo,
      type: props.tradeType,
      update,
      updateTxn: (txn: TxnUpdate): void => {
        reduxDispatch(updateTxn({ ...txn, formType: 'desktop' }))
      },
      startTxn: (txn: TxnUpdate): void => {
        reduxDispatch(startTxn({ ...txn, formType: 'desktop' }))
      },
      resetTxn: (txn: TxnUpdate): void => {
        reduxDispatch(resetTxn({ ...txn, formType: 'desktop' }))
      },
      errorTxn: (txn: TxnUpdate): void => {
        reduxDispatch(errorTxn({ ...txn, formType: 'desktop' }))
      },
      cancelTxn: (txn: TxnUpdate): void => {
        reduxDispatch(cancelTxn({ ...txn, formType: 'desktop' }))
      },
      tokenNetwork: tokenFrom?.network,
      spender: spender,
    }
  }

  const getSimpleQuote = async (
    value?: BigNumber,
    limitOrderRate?: BigNumber,
    isNew?: boolean
  ): Promise<BigNumber | undefined> => {
    if (!tokenFrom?.id || !tokenTo?.id || value?.eq(0) || txn.isLoading) {
      // do not get quote if there is no input in form
      return
    }

    const serviceProps = getServiceProps()
    const sellAmount = getSellAmount('', tokenFrom?.decimals, value)

    const txnUpdatePayload = await transactionService.getSimpleSwapEstimate({
      ...serviceProps,
      sellAmount,
      isNew: isNew,
      orderRate: limitOrderRate,
    })
    if (txnUpdatePayload.patch?.quoteResponse) {
      const quoteResponse = txnUpdatePayload.patch?.quoteResponse
      const limitOrderRate =
        serviceProps.type === TradeTypeEnum.sell
          ? new BigNumber(quoteResponse.buyAmount)
              .div(quoteResponse.sellAmount)
              .div(10 ** (tokenTo.decimals - tokenFrom.decimals))
          : new BigNumber(quoteResponse.sellAmount)
              .div(quoteResponse.buyAmount)
              .div(10 ** (tokenFrom.decimals - tokenTo.decimals))

      dispatch({ type: ActionTypes.ChangeLimitOrderRate, limitOrderRate: limitOrderRate })
    }
  }

  useEffect(() => {
    if (contrTxn.txnVerificationSuccess && !contrTxn.txnVerificationInProgress) {
      sequenceActions({ balanceAction: 'getBalance' })
    }
    // TODO:
    // https://app.shortcut.com/dexguru/story/21246/chore-fix-warnings-in-trading-form-components-15
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contrTxn.txnVerificationInProgress])

  useEffect(() => {
    sequenceActions({ tokenAction: 'checkAllowance' })
    // on form variant change (spender change) we should check for allowance  for the new spender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spender])

  useEffect(() => {
    if (state.name !== FormStateTypes.TXN_IN_PROGRESS) {
      return
    }

    if (txn.txnVerificationSuccess && !txn.txnVerificationInProgress) {
      return dispatch({
        type: ActionTypes.SetStateLoading,
        state: FormStateTypes.LOADING,
        tokens: { tokenFrom, tokenTo },
      })
    }
    // TODO:
    // https://app.shortcut.com/dexguru/story/21246/chore-fix-warnings-in-trading-form-components-15
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modalVerifyOpen,
    txn.txnVerificationInProgress,
    contrTxn.txnVerificationInProgress,
    state.name,
  ])

  const sequenceActions = async (actions: Partial<FormState>): Promise<void> => {
    try {
      const serviceProps = getServiceProps()
      if (actions.txnAction === 'clear') {
        transactionService.clearTransaction(serviceProps)
      }
      if (actions.tokenAction === 'clear') {
        transactionService.clearToken(serviceProps)
      }
      if (actions.tokenAction === 'checkAllowance') {
        await transactionService.checkTokenAllowance(serviceProps)
      }
      if (actions.balanceAction === 'getBalance') {
        if (actions.name !== FormStateTypes.OK) {
          dispatch({ type: ActionTypes.ChangeBalance, balance: new BigNumber(0) })
        }
        const balance = await getUserBalance(serviceProps)
        dispatch({ type: ActionTypes.ChangeBalance, balance })
      }
      if (actions.balanceAction === 'clear') {
        dispatch({ type: ActionTypes.ChangeBalance, balance: new BigNumber(0) })
      }
      if (actions.gasPriceAction) {
        await transactionService.getGasPrice(serviceProps)
      }

      if (actions.name === FormStateTypes.LOADING) {
        dispatch({
          type: ActionTypes.ChangeState,
          state: FormStateTypes.READY,
        })
      }
    } catch (error) {
      const { message } = error as { message: string | undefined }
      actions.name = FormStateTypes.ERROR
      console.warn('check error', error)
      if (message === RPC_ERR) {
        return dispatch({
          type: ActionTypes.ChangeState,
          state: FormStateTypes.ERROR,
          addon: {
            error: tokenFrom.network === 'polygon' ? RPC_ERROR_MESSAGE_POLYGON : RPC_ERROR_MESSAGE,
            showError: true,
          },
        })
      }

      return dispatch({
        type: ActionTypes.ChangeState,
        state: FormStateTypes.ERROR,
        addon: {
          error: message,
          showError: !!message,
        },
      })
    }
  }

  useEffect(() => {
    sequenceActions(state)
    // TODO:
    // https://app.shortcut.com/dexguru/story/21246/chore-fix-warnings-in-trading-form-components-15
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.name, spender])

  useEffect(() => {
    if (state.amountFrom.gt(0)) {
      const tokenAllowance = new BigNumber(approvalContext?.tokenAllowance || 0)

      if (tokenAllowance.isFinite() && state.amountFrom.gt(tokenAllowance) && walletNetwork) {
        update({ [spender]: { isTokenApproved: false } })
      }

      getSimpleQuote(
        state.amountFrom,
        state.limitOrderRate,
        state.cashAmount.eq(0) && state.amountFrom.gt(0)
      )
    } else if (state.amountFrom.eq(0) && state.cashAmount.gt(0)) {
      const serviceProps = getServiceProps()
      transactionService.cancelTransactionByUser({ ...serviceProps, lastRequest: '' })
    }
    // TODO:
    // https://app.shortcut.com/dexguru/story/21246/chore-fix-warnings-in-trading-form-components-15
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.amountFrom.toString()])

  useEffect(() => {
    if (props.formVariant !== FormVariants.limit) {
      return
    }
    if (state.limitOrderRate?.gt(0) && state.amountFrom.gt(0) && txn.quoteResponse) {
      const tokenAllowance = new BigNumber(approvalContext?.tokenAllowance || 0)

      if (tokenAllowance.isFinite() && state.amountFrom.gt(tokenAllowance) && walletNetwork) {
        update({ [spender]: { isTokenApproved: false } })
      }

      const serviceProps = getServiceProps()
      const sellAmount = getSellAmount('', tokenFrom?.decimals, state.amountFrom)

      transactionService.getLimitOrderEstimate({
        ...serviceProps,
        sellAmount,
        quoteResponse: txn.quoteResponse,
        limitOrderRate: state.limitOrderRate,
        isNew: false,
      })
    } else if (state.amountFrom.gt(0)) {
      // when limit input is erased, just calculate market price
      getSimpleQuote(
        state.amountFrom,
        state.limitOrderRate,
        state.cashAmount.eq(0) && state.amountFrom.gt(0)
      )
    } else {
      const serviceProps = getServiceProps()
      transactionService.cancelTransactionByUser({ ...serviceProps, lastRequest: '' })
    }
    // TODO:
    // https://app.shortcut.com/dexguru/story/21246/chore-fix-warnings-in-trading-form-components-15
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.limitOrderRate?.toFixed()])

  useEffect(() => {
    if (
      walletNetwork &&
      ((state.name === FormStateTypes.OK && state.amountFrom.gt(state.userBalance)) ||
        (state.name === FormStateTypes.NOT_APPROVED && state.amountFrom.gt(state.userBalance)))
    ) {
      dispatch({
        type: ActionTypes.ChangeState,
        state: FormStateTypes.INSUFFICIENT_BALANCE,
      })
    } else if (
      state.name === FormStateTypes.INSUFFICIENT_BALANCE &&
      state.amountFrom.lte(state.userBalance)
    ) {
      dispatch({ type: ActionTypes.ChangeState, state: FormStateTypes.READY })
    }

    if (state.name === FormStateTypes.ERROR && !state.cashAmount.eq(state.amountFrom)) {
      const serviceProps = getServiceProps()
      transactionService.clearTransaction(serviceProps)
      transactionService.clearTokenError(serviceProps)
    }
    // TODO:
    // https://app.shortcut.com/dexguru/story/21246/chore-fix-warnings-in-trading-form-components-15
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.name, state.userBalance, state.amountFrom])

  useEffect(() => {
    if (
      state.name === FormStateTypes.APPROVE_IN_PROGRESS &&
      txn.approvalInProgress !== null &&
      !txn.approvalInProgress
    ) {
      if (approvalContext?.isTokenApproved) {
        dispatch({ type: ActionTypes.UpdateToken, tokens: { tokenFrom } })
        verifyHandler()
      }

      if (!approvalContext?.isTokenApproved) {
        dispatch({
          type: ActionTypes.ChangeState,
          state: FormStateTypes.NOT_APPROVED,
          addon: { tokenFrom },
        })
      }
    }

    if (state.name === FormStateTypes.READY && approvalContext?.isTokenApproved !== null) {
      if (approvalContext?.tokenApproveError === RPC_ERR) {
        dispatch({
          type: ActionTypes.ChangeState,
          state: FormStateTypes.ERROR,
          addon: {
            error: tokenFrom.network === 'polygon' ? RPC_ERROR_MESSAGE_POLYGON : RPC_ERROR_MESSAGE,
            showError: true,
          },
        })
      } else {
        dispatch({ type: ActionTypes.UpdateToken, tokens: { tokenFrom } })
      }
    }

    if (state.name === FormStateTypes.OK && approvalContext?.isTokenApproved === false) {
      dispatch({
        type: ActionTypes.ChangeState,
        state: FormStateTypes.NOT_APPROVED,
      })
    }

    if (state.name === FormStateTypes.NOT_APPROVED && approvalContext?.isTokenApproved === true) {
      dispatch({
        type: ActionTypes.ChangeState,
        state: FormStateTypes.READY,
      })
    }
    // TODO:
    // https://app.shortcut.com/dexguru/story/21246/chore-fix-warnings-in-trading-form-components-15
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txn.approvalInProgress, approvalContext?.isTokenApproved, state.name, approvalContext])

  useEffect(() => {
    clearBalancePollingInterval()
    if (tokenFrom) {
      balancePollingInterval.current = setInterval(handleVisibilityChange, BALANCE_POOLING_TIMEOUT)
    }
    dispatch({ type: ActionTypes.ChangeLimitOrderRate, limitOrderRate: undefined })

    if (!walletNetwork) {
      return dispatch({
        type: ActionTypes.ChangeState,
        state: FormStateTypes.NO_NETWORK_LOADING,
      })
    }

    if (walletNetwork !== tokenFrom.network || tokenFrom.network !== tokenTo.network) {
      return dispatch({
        type: ActionTypes.ChangeState,
        state: FormStateTypes.WRONG_NETWORK_DESKTOP,
      })
    }

    dispatch({
      type: ActionTypes.SetStateLoading,
      state: FormStateTypes.LOADING,
      tokens: { tokenFrom, tokenTo },
    })

    // TODO:
    // https://app.shortcut.com/dexguru/story/21246/chore-fix-warnings-in-trading-form-components-15
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenFrom?.id, tokenTo?.id, walletNetwork])

  useEffect(() => {
    if (!state?.tokenFrom) {
      return
    }

    if (state.name === FormStateTypes.WRONG_NETWORK_DESKTOP) {
      return
    }

    if (state.name === FormStateTypes.READY && approvalContext?.isTokenApproved === false) {
      return dispatch({
        type: ActionTypes.ChangeState,
        state: FormStateTypes.NOT_APPROVED,
      })
    }

    if (txn.txnError?.message) {
      if (txn.txnError.message === `${REQUEST_DENIED_BY_USER_ERROR_CODE}`) {
        return dispatch({
          type: ActionTypes.SetStateLoading,
          state: FormStateTypes.LOADING,
          tokens: { tokenFrom, tokenTo },
        })
      }
      return dispatch({
        type: ActionTypes.ChangeState,
        state: FormStateTypes.ERROR,
        addon: { error: txn.txnError.message, showError: true, balanceAction: 'getBalance' },
      })
    }

    if (state.tokenFrom?.error) {
      return dispatch({
        type: ActionTypes.ChangeState,
        state: FormStateTypes.ERROR,
        addon: {
          error: state.tokenFrom?.error?.message,
          showError: true,
          balanceAction: 'getBalance',
        },
      })
    }

    if (approvalContext?.tokenApproveError) {
      if (approvalContext?.tokenApproveError === RPC_ERR) {
        return dispatch({
          type: ActionTypes.ChangeState,
          state: FormStateTypes.ERROR,
          addon: {
            showError: true,
            error: tokenFrom.network === 'polygon' ? RPC_ERROR_MESSAGE_POLYGON : RPC_ERROR_MESSAGE,
            balanceAction: 'getBalance',
          },
        })
      }
      return dispatch({
        type: ActionTypes.ChangeState,
        state: FormStateTypes.ERROR,
        addon: {
          error: approvalContext?.tokenApproveError,
          showError: true,
          balanceAction: 'getBalance',
        },
      })
    }

    if (state.name === FormStateTypes.READY && approvalContext?.isTokenApproved) {
      dispatch({ type: ActionTypes.ChangeState, state: FormStateTypes.OK })
    }
    // TODO:
    // https://app.shortcut.com/dexguru/story/21246/chore-fix-warnings-in-trading-form-components-15
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.name,
    state?.tokenFrom?.id,
    state?.tokenTo?.id,
    approvalContext?.isTokenApproved,
    txn.txnError,
    state?.tokenFrom?.error,
    approvalContext?.tokenApproveError,
    spender,
  ])

  useEffect(() => {
    if (modalVerifyOpen === props.tradeType) {
      dispatch({
        type: ActionTypes.ChangeState,
        state: FormStateTypes.TXN_IN_PROGRESS,
        addon: { showError: false, error: '' },
      })
    }
    // TODO:
    // https://app.shortcut.com/dexguru/story/21246/chore-fix-warnings-in-trading-form-components-15
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVerifyOpen])

  useEffect(() => {
    clearBalancePollingInterval()
    balancePollingInterval.current = setInterval(handleVisibilityChange, BALANCE_POOLING_TIMEOUT)

    document.addEventListener('visibilitychange', handleVisibilityChange, false)
    window.addEventListener('online', handleVisibilityChange)
    document.addEventListener('resume', handleVisibilityChange)

    return (): void => {
      clearBalancePollingInterval()
      document.removeEventListener('visibilitychange', handleVisibilityChange, false)
      window.removeEventListener('online', handleVisibilityChange)
      document.removeEventListener('resume', handleVisibilityChange)
    }
    // TODO:
    // https://app.shortcut.com/dexguru/story/21246/chore-fix-warnings-in-trading-form-components-15
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenFrom?.id, tokenTo?.id, walletNetwork])

  const verifyHandler = async (): Promise<void> => {
    if (!account || !library) {
      openProviderMenu()
      return
    }

    if (state.name === FormStateTypes.TXN_IN_PROGRESS) {
      return
    }

    if (!state.amountFrom.gt(0)) {
      dispatch({ type: ActionTypes.SetFocus, isFocus: true })
      return
    }

    reduxDispatch(actions.setActiveTradeType(props.tradeType))
    reduxDispatch(setModalVerifyOpen(props.tradeType))
  }

  const approveHandler = async (): Promise<void> => {
    if (!account || !library) {
      openProviderMenu()
      return
    }

    if (state.name === FormStateTypes.APPROVE_IN_PROGRESS) {
      return
    }

    if (!state.amountFrom.gt(0)) {
      dispatch({ type: ActionTypes.SetFocus, isFocus: true })
      return
    }

    if (!tokenFrom.id || !tokenTo.id) {
      return
    }

    reduxDispatch(actions.setActiveTradeType(props.tradeType))
    // TODO why is this not done by transactionService?
    update({ [spender]: { approvalComplete: null } })
    reduxDispatch(
      updateTxn({
        key: props.tradeType,
        patch: {
          // TODO Why null? shouldn't it be true?
          approvalInProgress: null,
          fromTokenAddress: tokenFrom?.address,
          toTokenAddress: tokenTo?.address,
          lastRequest: '',
        },
        formType: 'desktop',
      })
    )

    dispatch({
      type: ActionTypes.ChangeState,
      state: FormStateTypes.APPROVE_IN_PROGRESS,
    })

    const serviceProps = getServiceProps()
    const sellAmount = getSellAmount(state.amountFrom.toFixed(), tokenFrom?.decimals)

    await transactionService.approve({
      ...serviceProps,
      sellAmount,
    })
  }

  const modalCloseHandler = (txnAwaiting = false): void => {
    const serviceProps = getServiceProps()
    transactionService.clearTransactionError(serviceProps)
    reduxDispatch(setModalVerifyOpen(undefined))

    if (!txnAwaiting) {
      transactionService.cancelTransactionByUser({ ...serviceProps, lastRequest: '' })
      dispatch({
        type: ActionTypes.ChangeState,
        state: FormStateTypes.LOADING,
        addon: { showError: false },
      })
    }
  }

  const onInputChange = (value: BigNumber): void => {
    if (!mounted) {
      return
    }

    // TODO New transaction could start here, includes % buttons due to hook

    dispatch({ type: ActionTypes.ChangeAmount, amountFrom: value })
  }

  const onLimitInputChangeHandler = (value: string): void => {
    if (!mounted) {
      return
    }

    dispatch({
      type: ActionTypes.ChangeLimitOrderRate,
      limitOrderRate: !value ? undefined : new BigNumber(value),
    })
  }
  const onFormToChangeHandler = (value: string): void => {
    const estimateTo = txn.estimatedAmount
    if (!mounted || !state.limitOrderRate || !estimateTo) {
      return
    }
    const amountTo = new BigNumber(value)
    const rate =
      props.tradeType === TradeTypeEnum.sell ? amountTo.div(estimateTo) : estimateTo.div(amountTo)
    const newLimitOrderRate = state.limitOrderRate.times(rate)
    reduxDispatch(
      updateTxn({
        key: props.tradeType,
        patch: {
          estimatedAmount: amountTo,
          lastRequest: '',
        },
        formType: 'desktop',
      })
    )

    dispatch({
      type: ActionTypes.ChangeLimitOrderRate,
      limitOrderRate: newLimitOrderRate,
    })
  }

  return (
    <div
      className={classNames(
        'tradeform-tab',
        `tradeform-tab--${props.tradeType.toLowerCase()}`,
        {
          active: state.isFocus,
        },
        {
          selected: activeTradeType === props.tradeType,
        }
      )}>
      <div
        className="tradeform-tab__header"
        onClick={(): void => props.onSelectTradeType(props.tradeType)}>
        <Heading size="md" className="tradeform-tab__title">
          {props.tradeType}
        </Heading>
      </div>
      <section className="tradeform-tab__body">
        <FormFrom
          account={account}
          walletNetwork={walletNetwork}
          tradeType={props.tradeType}
          userBalance={state.userBalance || new BigNumber(0)}
          onFromInputChangeStr={onInputChange}
          tokenFrom={tokenFrom}
          isEmpty={state.amountFrom.lte(0)}
          disabled={Boolean(
            state.name === FormStateTypes.WRONG_NETWORK_DESKTOP || txn.estimateLoading
          )}
          disabledInput={state.name === FormStateTypes.APPROVE_IN_PROGRESS}
          formVariant={props.formVariant}
        />
        {props.formVariant === FormVariants.limit && (
          <FormLimit onChange={onLimitInputChangeHandler} limitOrderRate={state.limitOrderRate} />
        )}
        <FormTo
          isShowRate={props.formVariant !== FormVariants.limit}
          isEmpty={state.amountFrom.lte(0)}
          tradeType={props.tradeType}
          fromAmount={state.amountFrom}
          readOnly={props.formVariant !== FormVariants.limit}
          onChange={props.formVariant === FormVariants.limit ? onFormToChangeHandler : noop}
          formVariant={props.formVariant}
          tokenFrom={tokenFrom}
          tokenTo={tokenTo}
        />
        <div className="tradeform__error tradeform__error--desktop">
          {state.showError && modalVerifyOpen !== props.tradeType && (
            <Message type="error" size="sm" text={state.error} />
          )}
        </div>
        <FormButtonDesktop
          fromAmount={state.amountFrom}
          currentState={state.name}
          errorMsg={state.error}
          tradeType={props.tradeType}
          onVerify={verifyHandler}
          spender={spender}
          onApprove={approveHandler}
          onRefreshQuote={(): void => {
            getSimpleQuote(state.amountFrom)
          }}
        />
        <Suspense fallback={<></>}>
          {modalVerifyOpen === props.tradeType && props.formVariant === FormVariants.market && (
            <ModalVerify onClose={modalCloseHandler} />
          )}
        </Suspense>
        <Suspense fallback={<></>}>
          {modalVerifyOpen === props.tradeType && props.formVariant === FormVariants.limit && (
            <LimitOrderConfirmationModal onClose={modalCloseHandler} />
          )}
        </Suspense>
      </section>
    </div>
  )
}

export default React.memo(FormDesktop, (prevProps, nextProps) => {
  return (
    prevProps.tradeType === nextProps.tradeType && prevProps.formVariant === nextProps.formVariant
  )
})
